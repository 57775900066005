html {
  box-sizing: border-box;
  font-size: 16px;
}

body {
  font-family: 'Metropolis';
  p,
  text {
    font-family: 'Metropolis';
  }
}

body[dir='rtl'] {
  direction: rtl;
}

.MuiDataGrid-cell:focus {
  outline: 0px !important;
}
